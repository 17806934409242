import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../extra/Input";
import Button from "../../../extra/Button";
import { editData, generateNum, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import {
  inquiryAdd,
  inquiryUpdate,
} from "../../../../redux/slice/inquirySlice";
import DialogBox from "../../../extra/DialogBox";

const InquiryAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
    }
  }, [dialogueData]);

  const handleSubmit = async (e) => {
    const addInquiry = submitData(e);
    if (addInquiry) {
      // console.log("addInquiry", addInquiry);
      // const formData = objectToFormData(addInquiry);
      try {
        let response;
        if (dialogueData) {
          const payload = { addInquiry, inquiryId: dialogueData._id };
          response = await dispatch(inquiryUpdate(payload)).unwrap();
        } else {
          response = await dispatch(inquiryAdd(addInquiry)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const dispatch = useDispatch();
  return (
    <DialogBox
      id={`inquireForm`}
      title={`Inquiry Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xl-5 col-md-8 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-lg-6 col-12">
          <Input
            type={`text`}
            id={`name`}
            name={`name`}
            label={`Name`}
            errorMessage={`Enter Name`}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            type={`number`}
            id={`inquiryId`}
            name={`inquiryId`}
            label={`Form Code`}
            errorMessage={`Enter Form Code`}
            validation={`^[a-zA-Z0-9]{6,6}$`}
            validationError={`Invalid Form Code`}
            activeIcon={!dialogueData && `ri-information-line`}
            activClick={() => generateNum(6, "inquiryId")}
            readOnly
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            type={`number`}
            id={`mobileNo`}
            name={`mobileNo`}
            label={`Mobile Number`}
            errorMessage={`Enter Mobile Number`}
            validation={`^[0-9]{10,10}$`}
            validationError={`Invalid Mobile Number`}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            type={`text`}
            id={`course`}
            name={`course`}
            label={`Course`}
            errorMessage={`Enter Course`}
          />
        </div>

        <div className="col-lg-3 col-6 align-self-center">
          <Input
            type={`radio`}
            id={`male`}
            name={`gender`}
            label={`Male`}
            value={`male`}
          />
        </div>
        <div className="col-lg-3 col-6 align-self-center">
          <Input
            type={`radio`}
            id={`female`}
            name={`gender`}
            label={`Female`}
            value={`female`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default InquiryAdd;
