import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { act } from "react-dom/test-utils";

const initialState = {
  dashBoard: {},
  employeeDashboard: {},
  loginUser: {},
  isLoading: false,
  isSkeleton: false,
};

export const dashBoardGet = createAsyncThunk("dashBoard/show", async () => {
  return apiInstance.get("dashBoard/show");
});
export const getLoginUser = createAsyncThunk(
  "staff/showEmployeeLogin",
  async (payload) => {
    if (payload) {
      return apiInstance.get(`staff/showEmployeeLogin?staffId=${payload}`);
    }
  }
);
export const employeeDashData = createAsyncThunk(
  "dashboard/employeeDashboard",
  async (payload) => {
    return apiInstance.get(
      `dashboard/employeeDashboard?staffId=${payload.staffId}&monthId=${payload.monthId}&year=${payload.year}`
    );
  }
);

const dashBoardSlice = createSlice({
  name: "dashBoardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // dashBoardSlice
    builder.addCase(dashBoardGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(dashBoardGet.fulfilled, (state, action) => {
      state.dashBoard = action.payload.dashBoard;
      state.isSkeleton = false;
    });
    builder.addCase(dashBoardGet.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getLoginUser.fulfilled, (state, action) => {
      state.loginUser = action?.payload?.staff;
    });

    // employeeDashData
    builder.addCase(employeeDashData.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(employeeDashData.fulfilled, (state, action) => {
      state.employeeDashboard = action.payload.employeeDashData;
      state.isSkeleton = false;
    });
    builder.addCase(employeeDashData.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default dashBoardSlice.reducer;
