import React, { useEffect, useState } from "react";
import Table from "../../../extra/Table";
import Title from "../../../extra/Title";
import Searching from "../../../extra/Searching";
import { useDispatch, useSelector } from "react-redux";
import Button, { ActionButton } from "../../../extra/Button";
import { openDialog } from "../../../../redux/slice/dialogSlice";
import { Select } from "../../../extra/Input";
import {
  staffAttendanceGet,
  staffSalaryRequest,
} from "../../../../redux/slice/staffAttendanceSlice";
import StaffReport from "../Salary/StaffReport";
import StaffAttendanceDetails from "./StaffAttendanceDetails";
import StaffAttendanceChart from "./StaffAttendanceChart";
const StaffAttendanceAll = ({ showAttendance, setShowAttendance }) => {
  const { dialogueType, dialogue } = useSelector((state) => state.dialogue);

  const reGetData = localStorage.getItem("attendanceShow");
  const mainData = showAttendance || JSON.parse(reGetData);

  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [years, setYears] = useState("2024");

  const { attendance, attendanceYear } = useSelector(
    (state) => state.staffAttendance
  );

  // Search BOTH
  const [search, setSearch] = useState("");
  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  // Server Get
  useEffect(() => {
    if (mainData?.attendanceData?._id) {
      console.log("mainData?.attendanceData", mainData?.attendanceData);
      dispatch(
        staffAttendanceGet({
          staffId: mainData?.attendanceData?._id,
          year: years,
          command: false,
        })
      );
    }
  }, [search, mainData?.attendanceData, years]);

  useEffect(() => {
    if (mainData?.attendanceData?._id) {
      dispatch(
        staffAttendanceGet({
          staffId: mainData?.attendanceData?._id,
          year: years,
          command: true,
        })
      );
    }
  }, []);
  useEffect(() => {
    setData(attendance);
  }, [attendance]);

  const handleSentRequest = (salaryData) => {
    console.log("salaryData", salaryData);
    dispatch(staffSalaryRequest(salaryData));
  };

  const attendanceTable = [
    {
      Header: "No",
      Cell: ({ index }) => <span>{parseInt(index) + 1}</span>,
    },
    {
      Header: "Month",
      body: "month",
      bText: "-",
      bBody: "monthId",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Year",
      body: "year",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Total Hours",
      body: "totalHours",
      aText: "Hour",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Total Break Hours",
      body: "totalBreakHours",
      aText: "Hour",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Final Total",
      body: "finalTotal",
      aText: "Hour",
      sorting: { type: "client" },
      tdClass: "text-capitalize text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <span>
          <ActionButton
            className="bg-success-light text-success  border-success-1  m5-right"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "StaffAttendanceChart",
                  data: { ...row },
                })
              )
            }
            icon={`ri-file-info-line`}
          />
          {!mainData?.attendanceData?.selfAccess && (
            <ActionButton
              className="bg-blue-light text-blue  border-blue-1  m5-right"
              onClick={() =>
                dispatch(
                  openDialog({
                    type: "staffAttendanceDetails",
                    data: { ...row },
                  })
                )
              }
              bIcon={`ri-calendar-check-line`}
              text={`Attendance`}
            />
          )}
        </span>
      ),
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Verify Salary",
      Cell: ({ row }) => (
        <span className="midBox">
          {row.payingRequest ? (
            <i class="ri-check-double-line fs-20 text-success"></i>
          ) : (
            <i class="ri-loader-2-line fs-20 text-blue"></i>
          )}
          <ActionButton
            className="bg-success-light text-success  border-success-1  m15-left"
            onClick={() =>
              dispatch(
                openDialog({
                  type: "staffInfo",
                  data: { ...row },
                })
              )
            }
            bIcon={`ri-file-chart-line`}
            text={`Report`}
          />
        </span>
      ),
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
    {
      Header: "Pay",
      Cell: ({ row }) => (
        <span className="midBox">
          {row.isPay ? (
            <i class="ri-checkbox-circle-line fs-20 text-success"></i>
          ) : (
            <i class="ri-close-circle-line fs-20 text-danger"></i>
          )}
        </span>
      ),
      tdClass: "text-center ",
      thClass: "justify-content-center",
    },
  ];

  return (
    <div className="dialoguePage">
      <div className="row justify-content-between align-items-center align-items-center adminPageStart">
        <div className="col-sm-6 col-12">
          <Title
            name={`${mainData?.attendanceData?.staffName}'s Attendance`}
            icon={`ri-timer-2-line`}
          />
        </div>
        <div className="col-sm-6 col-12 text-end d-flex align-items-center justify-content-sm-end  justify-content-center">
          <div className="m10-right">
            <Searching
              type={`client`}
              data={attendance}
              setData={setData}
              column={attendanceTable}
              serverSearching={handleFilterData}
              className={`w-100`}
            />
          </div>
          <div>
            <Select
              option={attendanceYear}
              defaultValue={years}
              onChange={(e) => setYears(e)}
            />
          </div>
          <div>
            <Button
              className={`bg-second-light text-second border-second-2 m10-left`}
              icon={`ri-close-line`}
              onClick={() => {
                localStorage.removeItem("attendanceShow");
                setShowAttendance({ open: false });
              }}
            />
          </div>
        </div>
      </div>

      <Table data={data} mapData={attendanceTable} />

      {dialogue && dialogueType === "staffAttendanceDetails" && (
        <StaffAttendanceDetails access={true} />
      )}
      {dialogue && dialogueType === "StaffAttendanceChart" && (
        <StaffAttendanceChart />
      )}
      {dialogue && dialogueType === "staffInfo" && (
        <StaffReport handleSentRequest={handleSentRequest} />
      )}
    </div>
  );
};

export default StaffAttendanceAll;
