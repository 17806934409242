import "./App.css";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import "./assets/scss/custom/custom.css";
import "./assets/scss/default/default.css";
import "./assets/scss/style/style.css";
import { setOldAdmin } from "./redux/slice/authSlice";
import { setToken } from "./api/axiosApi";
import Login from "./component/Pages/Login";
import AuthRoute from "./component/util/AuthRoute";
import Admin from "./component/Pages/Admin";
import { Route, Routes } from "react-router";
import Cookies from "universal-cookie";

function App() {
  const dispatch = useDispatch();
  const cookies = new Cookies(null, { path: "/" });
  const key = cookies.get("keyE");
  const tokenSil = cookies.get("tokenSilE");
  const token = cookies.get("tokenE");
  // const key = sessionStorage.getItem("keyE");
  // const tokenSil = sessionStorage.getItem("tokenSiEl");
  // const token = sessionStorage.getItem("tokenE");

  useEffect(() => {
    if (!tokenSil && !key && !token) return;
    dispatch(setOldAdmin({ tokenSil, token }));
  }, [setToken, key]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route element={<AuthRoute />}>
          <Route path="/admin/*" element={<Admin />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
