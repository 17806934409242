import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../component/extra/toast";

const initialState = {
  student: [],
  findStudent: "",
  studentTotal: 0,
  isLoading: false,
  isSkeleton: false,
};
export const studentGet = createAsyncThunk("student/show", async (payload) => {
  return apiInstance.get(
    `student/show?page=${payload.page}&limit=${payload.limit}&search=${payload.search}`
  );
});
export const findStudentByFormNo = createAsyncThunk(
  "student/studentFindByFormNo",
  async (formNo) => {
    return apiInstance.get(`student/studentFindByFormNo?formNo=${formNo}`);
  }
);
export const studentAdd = createAsyncThunk(
  "student/create",
  async (payload) => {
    return apiInstance.post("student/create", payload);
  }
);
export const studentUpdate = createAsyncThunk(
  "student/update",
  async (payload) => {
    return apiInstance.patch(
      `student/update?id=${payload.id}`,
      payload.formData
    );
  }
);
export const studentDelete = createAsyncThunk("student/delete", async (id) => {
  return apiInstance.delete(`student/delete?id=${id}`);
});

const studentSlice = createSlice({
  name: "studentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // studentGet
    builder.addCase(studentGet.pending, (state, action) => {
      state.isSkeleton = action.meta.arg.command;
    });
    builder.addCase(studentGet.fulfilled, (state, action) => {
      state.student = action.payload.student;
      state.studentTotal = action.payload.studentTotal;
      state.isSkeleton = false;
    });
    builder.addCase(studentGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // findStudentByFormNo
    builder.addCase(findStudentByFormNo.fulfilled, (state, action) => {
      action?.payload?.status
        ? (state.findStudent = action?.payload?.student)
        : (state.findStudent = "");
    });

    // studentCreate
    builder.addCase(studentAdd.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(studentAdd.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.student.unshift(action.payload.student);
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(studentAdd.rejected, (state, action) => {
      state.isLoading = false;
    });

    // studentUpdate
    builder.addCase(studentUpdate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(studentUpdate.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const studentIdx = state.student.findIndex(
          (student) => student._id === action.payload.student._id
        );
        if (studentIdx !== -1) {
          state.student[studentIdx] = {
            ...state.student[studentIdx],
            ...action.payload.student,
          };
        }
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(studentUpdate.rejected, (state, action) => {
      state.isLoading = false;
    });

    // studentDelete
    builder.addCase(studentDelete.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(studentDelete.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.student = state.student.filter(
          (student) => student._id !== action.meta.arg
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(studentDelete.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default studentSlice.reducer;
