import { useSelector } from "react-redux";
import { baseURL } from "../../../util/config";
import Input, { Textarea } from "../../../extra/Input";
import DialogBox from "../../../extra/DialogBox";

const StaffDetails = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  return (
    <DialogBox
      columns={`col-xxl-9 col-xl-10 col-lg-11 col-12`}
      foot={true}
      head={true}
    >
      <div className="mainProfileBoard mainAdminProfile formBody">
        <div className="mainProfileBox">
          <div className="row">
            <div className=" col-12 m-auto">
              <div className=" text-center">
                <div className="profileNameImage p50-x d-flex align-items-center flex-column justify-content-center">
                  <div className="profileMainImage m0-top m30-sm-right">
                    <div className="adminProfileImage hw-lg-120 hw-md-100 hw-80 m-auto  position-relative">
                      <div className="adminImageHeader hw-lg-120 hw-md-100 hw-80 m-auto overflow-hidden border-second-3">
                        <img
                          src={baseURL + dialogueData?.image}
                          alt="admin profile"
                          height={`100%`}
                          width={`100%`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="adminText m20-top text-center">
                    <h3 className="m0 fs-lg-24 fs-sm-20 fs-xsm-16 fs-14 text-second">
                      {dialogueData?.staffName}
                    </h3>
                    <p className="fs-lg-16 fs-xsm-12 fs-12">
                      {dialogueData?.email}
                    </p>
                  </div>
                </div>
              </div>

              <div className="row m40-sm-top m20-top">
                <div className="col-sm-6 col-12">
                  <div className="adminDetails bg-light p20">
                    <div className="adminProfileTitle">
                      Personal Information
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Name</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.staffName}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Date of birth</div>
                      <Input
                        type={`date`}
                        value={dialogueData?.dob}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox ">
                      <div className="adminDetailTitle">Gender</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.gender}
                        defaultValue={`--`}
                        disabled={true}
                        className={`text-capitalize`}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Addhar No</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.adharCard}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">PAN No</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.panCard}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Last Company</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.lastCompany}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Experience</div>
                      <Input
                        type={`text`}
                        id={`experience`}
                        value={`${dialogueData?.experience} Years`}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="adminDetails bg-light p20">
                    <div className="adminProfileTitle">Bank Details</div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Holder Name</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.holderName}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Account No</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.accountNo}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">IFSC</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.IFSC}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="adminDetails bg-light p20">
                    <div className="adminProfileTitle">Emergency Contect</div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Name</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.emergencyName}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Releation</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.emergencyRelation}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Phone No</div>
                      <Input
                        type={`text`}
                        value={`+91 ${dialogueData?.emergencyNumber}`}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="adminDetails bg-light p20">
                    <div className="adminProfileTitle">General Information</div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Job title</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.position}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Staff Code</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.staffCode}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Department</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.department}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Salary</div>
                      <Input
                        type={`text`}
                        value={`${dialogueData?.salary}₹`}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Start of work</div>
                      <Input
                        type={`date`}
                        value={dialogueData?.joiningDate}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Address</div>
                      <Textarea
                        type={`text`}
                        value={dialogueData?.address}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="adminDetails bg-light p20">
                    <div className="adminProfileTitle">Working Details</div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Punch In Time</div>
                      <Input
                        type={`time`}
                        value={dialogueData?.startTime}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Punch Out Time</div>
                      <Input
                        type={`time`}
                        value={dialogueData?.endTime}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Break Time/Hour</div>
                      <Input
                        type={`Text`}
                        value={`${dialogueData?.breakTime} Hr`}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Total Hour</div>
                      <Input
                        type={`Text`}
                        value={`${dialogueData?.totalTime} Hr`}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="adminDetails bg-light p20">
                    <div className="adminProfileTitle">Contact Details</div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Email</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.email}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                    <div className="adminDetailsBox">
                      <div className="adminDetailTitle">Phone No</div>
                      <Input
                        type={`text`}
                        value={dialogueData?.phoneNumber}
                        defaultValue={`--`}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DialogBox>
  );
};

export default StaffDetails;
