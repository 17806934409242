import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dashBoardGet,
  employeeDashData,
  getLoginUser,
} from "../../../redux/slice/dashBoardSlice";
import Button from "../../extra/Button";
import {
  breakIn,
  breakOut,
  punchIn,
  punchOut,
} from "../../../redux/slice/authSlice";
import { Textarea } from "../../extra/Input";
import { Chart } from "react-google-charts";
import Title from "../../extra/Title";
import DialogBox from "../../extra/DialogBox";

const Dashboard = () => {
  const { dashBoard, loginUser } = useSelector((state) => state.dashBoard);
  const {
    pieChartDetails,
    lineChartDetails,
    thisMonthData,
    staffAllAttendance,
  } = useSelector((state) => state.dashBoard?.employeeDashboard);

  const { admin } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

  const [isPunch, setIsPunch] = useState("");

  useEffect(() => {
    dispatch(getLoginUser(admin._id));
  }, [admin._id]);

  useEffect(() => {
    dispatch(dashBoardGet({ command: true }));
  }, []);
  const currDate = new Date();

  const liveMonth = currDate.getMonth() + 1;
  const liveYears = currDate.getFullYear();

  useEffect(() => {
    if (admin?._id) {
      dispatch(
        employeeDashData({
          staffId: admin._id,
          monthId: liveMonth,
          year: liveYears,
          command: true,
        })
      );
    }
  }, [admin._id]);

  useEffect(() => {
    setData(dashBoard);
  }, [dashBoard]);

  const hendlePunchData = async (punch, userId, punchDes) => {
    let response;
    if (punch) {
      response = await dispatch(punchIn({ staffId: loginUser._id, punchDes }));
    } else {
      response = await dispatch(
        punchOut({ staffId: loginUser.punchId, punchDes })
      );
    }
    if (response?.payload?.status) {
      dispatch(getLoginUser(userId));
      setIsPunch("");
    }
  };
  const hendleBreakPunchData = async (breaks, userId) => {
    let response;
    if (breaks) {
      response = await dispatch(breakIn(loginUser.punchId));
    } else {
      response = await dispatch(breakOut(loginUser.punchId));
    }
    if (response?.payload?.status) {
      dispatch(getLoginUser(userId));
    }
  };

  // Pie Chart
  const pieChartDataAll = [
    ["Task", "Hours per Day"],
    ["Ruguler", pieChartDetails?.regularPunch],
    ["Late", pieChartDetails?.latePunch],
    ["Holiday", pieChartDetails?.holidayPunch],
    ["Other", pieChartDetails?.extraPunch], // CSS-style declaration
  ];

  const pieChartOptions = {
    title: `${thisMonthData?.month} - ${thisMonthData?.year} Activities`,
    pieHole: 0.4,
    is3D: false,
    colors: ["#198754", "#DC3545", "#0D6EFD", "#6D7080"],
    legend: {
      position: "bottom",
      textStyle: { fontSize: 14 },
    },
  };

  // Line Chart
  let allPunchData = [];
  for (let i = 0; i < lineChartDetails?.punch?.length; i++) {
    let arr = [
      `${lineChartDetails?.punch[i]?.dayId}`,
      lineChartDetails?.punch[i]?.punchInt,
      lineChartDetails?.punch[i]?.punchOutInt,
    ];

    allPunchData.push(arr);
  }

  console.log("allPunchData", allPunchData);

  const lineChartDataAll = [
    ["Date", "Punch In Hour", "Punch Out Hour"],
    ...allPunchData,
  ];

  const lineChartOptions = {
    title: "User Performance",
    curveType: "function",
    colors: ["#198754", "#DC3545"],
    legend: { position: "bottom" },
    // backgroundColor: "transparent",
  };

  return (
    <div className="mainDashboard">
      <div className="dashBoardHead betBox">
        <div className="dashHeadText">
          <Title name={"Dashboard"} icon={`ri-home-5-line`} />
        </div>
        <div className="dashPunch">
          {loginUser?.isPunch && loginUser?.punchId ? (
            <>
              {loginUser?.isBreak ? (
                <Button
                  className={`bg-warning-light text-warning border-warning-2 m20-right`}
                  bIcon={`ri-door-open-line`}
                  text={`Break Out`}
                  onClick={() => hendleBreakPunchData(false, loginUser._id)}
                />
              ) : (
                <>
                  <Button
                    className={`bg-second-light text-second border-second-2 m20-right`}
                    bIcon={`ri-door-open-line`}
                    text={`Punch Out`}
                    // onClick={() => hendlePunchData(false, loginUser._id)}
                    onClick={() => setIsPunch(false)}
                  />
                  <Button
                    className={`bg-darkGray-light text-darkGray border-darkGray-2 m20-right`}
                    bIcon={`ri-door-open-line`}
                    text={`Break In`}
                    onClick={() => hendleBreakPunchData(true, loginUser._id)}
                  />
                </>
              )}
            </>
          ) : (
            <Button
              className={`bg-success-light text-success border-success-2 m20-right`}
              bIcon={`ri-door-open-line`}
              text={`Punch In`}
              // onClick={() => hendlePunchData(true, loginUser._id)}
              onClick={() => setIsPunch(true)}
            />
          )}
        </div>
      </div>

      <div className="dashBoardBody">
        {loginUser?.isHr && (
          <div className="hrSystem m20-bottom p20-bottom border-bottom-gray2-1">
            <Title name={`Employee Data`} className={`m10-bottom`} />
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
                <DashBox
                  title={`Total Staff`}
                  count={dashBoard?.totalStaff}
                  className={`p10`}
                  classNameBody={`fs-22 text-success`}
                  classNameHead={`fs-14`}
                  icon={`ri-calendar-2-line`}
                  color={`success`}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
                <DashBox
                  title={`Total Staff Leave`}
                  count={dashBoard?.totalStaffLeave}
                  className={`p10`}
                  classNameBody={`fs-22 text-darkGray`}
                  classNameHead={`fs-14`}
                  icon={`ri-calendar-2-line`}
                  color={`darkGray`}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
                <DashBox
                  title={`Total Staff Holiday`}
                  count={dashBoard?.totalStaffHoliday}
                  className={`p10`}
                  classNameBody={`fs-22 text-success`}
                  classNameHead={`fs-14`}
                  icon={`ri-calendar-2-line`}
                  color={`success`}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
                <DashBox
                  title={`Total Staff Receipt`}
                  count={dashBoard?.totalStaffSalaryReceipt}
                  className={`p10`}
                  classNameBody={`fs-22 text-darkGray`}
                  classNameHead={`fs-14`}
                  icon={`ri-calendar-2-line`}
                  color={`darkGray`}
                />
              </div>
            </div>
          </div>
        )}

        {loginUser?.isReceptionist && (
          <div className="reptionistSystem m20-bottom p20-bottom border-bottom-gray2-1">
            <Title name={`Student Data`} className={`m10-bottom`} />
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
                <DashBox
                  title={`Total Student`}
                  count={dashBoard?.totalStudent}
                  className={`p10`}
                  classNameBody={`fs-22 text-orange`}
                  classNameHead={`fs-14`}
                  icon={`ri-calendar-2-line`}
                  color={`orange`}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
                <DashBox
                  title={`Total Student Leave`}
                  count={dashBoard?.totalStudentLeave}
                  className={`p10`}
                  classNameBody={`fs-22 text-darkGray`}
                  classNameHead={`fs-14`}
                  icon={`ri-calendar-2-line`}
                  color={`darkGray`}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
                <DashBox
                  title={`Total Student Holiday`}
                  count={dashBoard?.totalStudentHoliday}
                  className={`p10`}
                  classNameBody={`fs-22 text-orange`}
                  classNameHead={`fs-14`}
                  icon={`ri-calendar-2-line`}
                  color={`orange`}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
                <DashBox
                  title={`Total Student Course`}
                  count={dashBoard?.totalStudentCourse}
                  className={`p10`}
                  classNameBody={`fs-22 text-darkGray`}
                  classNameHead={`fs-14`}
                  icon={`ri-calendar-2-line`}
                  color={`darkGray`}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
                <DashBox
                  title={`Total Student Batch`}
                  count={dashBoard?.totalStudentBatch}
                  className={`p10`}
                  classNameBody={`fs-22 text-orange`}
                  classNameHead={`fs-14`}
                  icon={`ri-calendar-2-line`}
                  color={`orange`}
                />
              </div>
              <div className="col-xl-3 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
                <DashBox
                  title={`Total Student Receipt`}
                  count={dashBoard?.totalStudentReceipt}
                  className={`p10`}
                  classNameBody={`fs-22 text-darkGray`}
                  classNameHead={`fs-14`}
                  icon={`ri-calendar-2-line`}
                  color={`darkGray`}
                />
              </div>
            </div>
          </div>
        )}

        <div className="employeeSystem">
          <Title name={`All User Data`} className={`m10-bottom text-second`} />
          <div className="row">
            <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Punch Hours`}
                count={staffAllAttendance?.totalHours || 0}
                className={`p10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
            <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Break Hours`}
                count={staffAllAttendance?.totalBreakHours || 0}
                className={`p10`}
                classNameBody={`fs-22 text-second`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`second`}
              />
            </div>
            <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12 m20-bottom">
              <DashBox
                title={`Total Working Hours`}
                count={staffAllAttendance?.finalTotal?.toFixed(2) || 0}
                className={`p10`}
                classNameBody={`fs-22 text-darkGray`}
                classNameHead={`fs-14`}
                icon={`ri-calendar-2-line`}
                color={`darkGray`}
              />
            </div>
          </div>
          <Title
            name={`March Punch Data`}
            className={`m10-bottom m20-top text-second`}
          />
          <div className="row">
            <div className="col-md-7 col-12">
              <div className="row employeeDash">
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Year`}
                    count={thisMonthData?.year}
                    className={`p10`}
                    classNameBody={`fs-22 text-darkGray`}
                    classNameHead={`fs-14`}
                    icon={`ri-calendar-event-line`}
                    color={`darkGray`}
                  />
                </div>
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Month`}
                    count={thisMonthData?.month}
                    className={`p10`}
                    classNameBody={`fs-22 text-second`}
                    classNameHead={`fs-14`}
                    icon={`ri-calendar-todo-line`}
                    color={`second`}
                  />
                </div>
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Punch Hours`}
                    count={thisMonthData?.totalHours || 0}
                    className={`p10`}
                    classNameBody={`fs-22 text-darkGray`}
                    classNameHead={`fs-14`}
                    icon={`ri-calendar-check-line`}
                    color={`darkGray`}
                  />
                </div>
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Break Hours`}
                    count={thisMonthData?.breakHours || 0}
                    className={`p10`}
                    classNameBody={`fs-22 text-second`}
                    classNameHead={`fs-14`}
                    icon={`ri-cup-line`}
                    color={`second`}
                  />
                </div>
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Total Hours`}
                    count={thisMonthData?.totalWorkingHours || 0}
                    className={`p10`}
                    classNameBody={`fs-22 text-darkGray`}
                    classNameHead={`fs-14`}
                    icon={`ri-hourglass-fill`}
                    color={`darkGray`}
                  />
                </div>
                <div className="col-xl-4 col-md-6 col-sm-4 col-xsm-6 col-12">
                  <DashBox
                    title={`Holiday`}
                    count={thisMonthData?.holiday || 0}
                    className={`p10`}
                    classNameBody={`fs-22 text-second`}
                    classNameHead={`fs-14`}
                    icon={`ri-calendar-close-line`}
                    color={`second`}
                  />
                </div>
              </div>
            </div>
            <div className=" col-md-5 col-12 m15-bottom">
              <Chart
                chartType="PieChart"
                width="100%"
                height="320px"
                data={pieChartDataAll}
                options={pieChartOptions}
                className="pieChart"
              />
            </div>
            <div className="col-12">
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={lineChartDataAll}
                options={lineChartOptions}
                className="lineChart"
              />
            </div>
          </div>
        </div>
      </div>

      {isPunch !== "" && (
        <PunchBox
          hendlePunchData={hendlePunchData}
          loginUser={loginUser}
          isPunch={isPunch}
          setIsPunch={setIsPunch}
        />
      )}
    </div>
  );
};

export default Dashboard;

export const PunchBox = ({
  hendlePunchData,
  loginUser,
  isPunch,
  setIsPunch,
}) => {
  const dispatch = useDispatch();

  const [punchInDes, setPunchInDes] = useState("");
  const [punchOutDes, setPunchOutDes] = useState("");

  return (
    <DialogBox
      columns={`col-3`}
      foot={true}
      title={"Punch Time"}
      closeClick={() => setIsPunch("")}
    >
      <div className="formBody p0-bottom">
        <div className="startForm  p0">
          <div className="col-12">
            {isPunch ? (
              <Textarea
                id={`punchInDes`}
                name={`punchInDes`}
                label={`Punch In Description`}
                row={4}
                errorMessage={`Enter Punch In Description`}
                onChangeVal={(e) => setPunchInDes(e.target.value)}
              />
            ) : (
              <Textarea
                id={`punchOutDes`}
                name={`punchOutDes`}
                label={`Punch Out Description`}
                row={4}
                errorMessage={`Enter Punch Out Description`}
                onChangeVal={(e) => setPunchOutDes(e.target.value)}
              />
            )}
          </div>
        </div>
      </div>
      <div className="row p0-x p0-top formFooter">
        <div className="col-12 text-end m0">
          <Button
            className={`bg-gray2 text-darkGray border-darkGray-2`}
            text={`Cancel`}
            type={`button`}
            onClick={() => setIsPunch("")}
          />
          {isPunch ? (
            <Button
              type={`submit`}
              className={`bg-success text-light border-success-light-2 m10-left`}
              text={`Punch In`}
              onClick={() => hendlePunchData(true, loginUser._id, punchInDes)}
            />
          ) : (
            <Button
              type={`submit`}
              className={`bg-orange text-light border-orange-light-2 m10-left`}
              text={`Punch Out`}
              onClick={() => hendlePunchData(false, loginUser._id, punchOutDes)}
            />
          )}
        </div>
      </div>
    </DialogBox>
  );
};

export const DashBox = ({
  title,
  count,
  color,
  icon,
  sideText,
  className,
  classNameHead,
  classNameBody,
}) => {
  return (
    <div
      className={`dashBox betBox align-items-end ${className} border-bottom-${color}-3 bg-light`}
    >
      <div>
        <div className={`boxHead betBox`}>
          <p className={`${classNameHead}`}>{title}</p>
        </div>
        <div className={`boxBody m5-sm-top m2-top d-flex align-items-end`}>
          <h2 className={`m0 ${classNameBody}`} style={{ color: `${color}` }}>
            {count}
          </h2>
          <p className="fs-12 m5-left text-darkGary fw-500">{sideText}</p>
        </div>
      </div>

      {icon && (
        <div
          className={`boxIcon m20-md-right m12-right d-flex justify-content-center align-items-center fs-sm-22 fs-20 hw-md-35 hw-25 bg-${color} text-light`}
          style={{
            color: `${color}`,
            backgroundColor: `${color}40`,
            borderRadius: "7px",
          }}
        >
          <i className={icon}></i>
        </div>
      )}
    </div>
  );
};
