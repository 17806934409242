import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import jwt_decode from "jwt-decode";

const initialState = {
  dialogue: false,
  dialogueType: "",
  dialogueData: null,
  dialogueText: null,
  alertBox: false,
};
export const login = createAsyncThunk("admin/login", async (payload) => {
  return apiInstance.post("admin/login", payload);
});

const dialogSlice = createSlice({
  name: "dialogSlice",
  initialState,
  reducers: {
    openDialog(state, action) {
      console.log("action", action);
      state.dialogue = true;
      state.dialogueType = action.payload.type || "";
      state.dialogueData = action.payload.data || null;
      state.dialogueText = action.payload.text || null;
    },
    closeDialog(state, action) {
      state.dialogue = false;
      state.dialogueType = "";
      state.dialogueData = null;
      state.dialogueText = null;
    },
    openAlert(state, action) {
      state.alertBox = true;
    },
    closeAlert(state, action) {
      state.alertBox = false;
    },
  },
});
export default dialogSlice.reducer;
export const { openDialog, closeDialog, openAlert, closeAlert } =
  dialogSlice.actions;
