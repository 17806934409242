import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import jwt_decode from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import { SetDevKey, apiInstance, setToken } from "../../api/axiosApi";
import { key } from "../../component/util/config";
import { setToast } from "../../component/extra/toast";
import Cookies from "universal-cookie";

const initialState = {
  admin: {},
  token: "",
  isAuth: false,
  isLoading: false,
};

export const employeeLogin = createAsyncThunk(
  "staff/employeeLogin",
  async (payload) => {
    return apiInstance.post("staff/employeeLogin", payload);
  }
);

// export const updateImage = createAsyncThunk(
//   "admin/updateImage",
//   async (payload) => {
//     return apiInstance.put("admin/updateImage", payload);
//   }
// );
export const updateStaffImage = createAsyncThunk(
  "staff/updateStaffImage",
  async (payload) => {
    return apiInstance.put("staff/updateStaffImage", payload);
  }
);

export const updateAdminData = createAsyncThunk(
  "admin/update",
  async (payload) => {
    return apiInstance.patch("admin/update", payload);
  }
);
export const updateAdminPassword = createAsyncThunk(
  "admin/updatePassword",
  async (payload) => {
    return apiInstance.patch("admin/updatePassword", payload);
  }
);
export const punchIn = createAsyncThunk(
  "staffAttendance/punchIn",
  async (payload) => {
    return apiInstance.post(
      `staffAttendance/punchIn?staffId=${payload.staffId}&punchInDes=${payload.punchDes}`
    );
  }
);
export const punchOut = createAsyncThunk(
  "staffAttendance/punchOut",
  async (payload) => {
    return apiInstance.post(
      `staffAttendance/punchOut?staffAttendanceId=${payload.staffId}&punchOutDes=${payload.punchDes}`
    );
  }
);
export const breakIn = createAsyncThunk(
  "staffAttendance/breakIn",
  async (payload) => {
    return apiInstance.post(
      `staffAttendance/breakIn?staffAttendanceId=${payload}`
    );
  }
);
export const breakOut = createAsyncThunk(
  "staffAttendance/breakOut",
  async (payload) => {
    return apiInstance.post(
      `staffAttendance/breakOut?staffAttendanceId=${payload}`
    );
  }
);

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setOldAdmin(state, action) {
      const cookieToken = action.payload.token;
      let token_;
      if (typeof cookieToken == "object") {
        token_ = action.payload.token;
      } else {
        token_ = JSON.parse(action.payload.token);
      }
      state.admin = token_;
      state.isAuth = true;
      state.token = action.payload.tokenSil;
      setToken(action.payload.tokenSil);
      SetDevKey(key);
    },
    logout(state, action) {
      const cookies = new Cookies(null, { path: "/" });
      sessionStorage.removeItem("tokenE");
      sessionStorage.removeItem("keyE");
      sessionStorage.removeItem("isAuthE");
      sessionStorage.removeItem("tokenSilE");
      cookies.remove("tokenE");
      cookies.remove("keyE");
      cookies.remove("isAuthE");
      cookies.remove("tokenSilE");
      state.admin = {};
      state.isAuth = false;
    },
  },
  extraReducers: (builder) => {
    // Admin Login
    builder.addCase(employeeLogin.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(employeeLogin.fulfilled, (state, action) => {
      const cookies = new Cookies(null, { path: "/" });
      if (action?.payload?.status) {
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        state.isAuth = true;
        state.token = action.payload.token;

        SetDevKey(key);
        setToken(action.payload.token);
        // Set Session
        sessionStorage.setItem(
          "tokenE",
          token_ ? JSON.stringify(token_) : undefined
        );
        sessionStorage.setItem(
          "tokenSilE",
          action.payload.token ? action.payload.token : undefined
        );
        sessionStorage.setItem("keyE", key ? key : undefined);
        sessionStorage.setItem("isAuthE", true);
        // Set Cookies
        cookies.set("tokenE", token_ ? JSON.stringify(token_) : undefined, {
          path: "/",
          maxAge: 2592000 * 6,
        });
        cookies.set(
          "tokenSilE",
          action.payload.token ? action.payload.token : undefined,
          { path: "/", maxAge: 2592000 * 6 }
        );
        cookies.set("keyE", key ? key : undefined, {
          path: "/",
          maxAge: 2592000 * 6,
        });
        cookies.set("isAuthE", true, { path: "/", maxAge: 2592000 * 6 });
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(employeeLogin.rejected, (state, action) => {
      state.isLoading = false;
    });

    // builder.addCase(updateImage.fulfilled, (state, action) => {
    //   if (action?.payload?.status) {
    //     let token_ = jwtDecode(action.payload.token);
    //     state.admin = token_;
    //     sessionStorage.setItem(
    //       "tokenE",
    //       token_ ? JSON.stringify(token_) : undefined
    //     );

    //     setToken(action.payload.token);
    //     sessionStorage.setItem(
    //       "tokenSilE",
    //       action.payload.token ? action.payload.token : undefined
    //     );

    //     setToast("success", action.payload.message);
    //   } else {
    //     setToast("error", action?.payload?.message);
    //   }
    // });

    builder.addCase(updateStaffImage.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateStaffImage.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        const cookies = new Cookies(null, { path: "/" });
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        sessionStorage.setItem(
          "tokenE",
          token_ ? JSON.stringify(token_) : undefined
        );

        setToken(action.payload.token);
        sessionStorage.setItem(
          "tokenSilE",
          action.payload.token ? action.payload.token : undefined
        );

        cookies.set(
          "tokenSilE",
          action.payload.token ? action.payload.token : undefined,
          { path: "/", maxAge: 2592000 * 6 }
        );
        cookies.set("tokenE", token_ ? JSON.stringify(token_) : undefined, {
          path: "/",
          maxAge: 2592000 * 6,
        });

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(updateStaffImage.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateAdminData.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        sessionStorage.setItem(
          "tokenE",
          token_ ? JSON.stringify(token_) : undefined
        );

        setToken(action.payload.token);
        sessionStorage.setItem(
          "tokenSilE",
          action.payload.token ? action.payload.token : undefined
        );

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
    });

    builder.addCase(updateAdminPassword.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        sessionStorage.setItem(
          "tokenE",
          token_ ? JSON.stringify(token_) : undefined
        );

        setToken(action.payload.token);
        sessionStorage.setItem(
          "tokenSilE",
          action.payload.token ? action.payload.token : undefined
        );

        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
    });

    // =================== Punch In Out =======================
    builder.addCase(punchIn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(punchIn.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        state.token = action.payload.token;

        setToken(action.payload.token);

        sessionStorage.setItem(
          "tokenE",
          token_ ? JSON.stringify(token_) : undefined
        );
        sessionStorage.setItem(
          "tokenSilE",
          action.payload.token ? action.payload.token : undefined
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(punchIn.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(punchOut.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(punchOut.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        state.token = action.payload.token;

        setToken(action.payload.token);
        sessionStorage.setItem(
          "tokenE",
          token_ ? JSON.stringify(token_) : undefined
        );
        sessionStorage.setItem(
          "tokenSilE",
          action.payload.token ? action.payload.token : undefined
        );
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
      state.isLoading = false;
    });
    builder.addCase(punchOut.rejected, (state, action) => {
      state.isLoading = false;
    });
    // =================== Break In Out =======================
    builder.addCase(breakIn.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(breakIn.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        state.token = action.payload.token;

        setToken(action.payload.token);

        sessionStorage.setItem(
          "tokenE",
          token_ ? JSON.stringify(token_) : undefined
        );
        sessionStorage.setItem(
          "tokenSilE",
          action.payload.token ? action.payload.token : undefined
        );
        state.isLoading = false;
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
    });
    builder.addCase(breakIn.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(breakOut.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(breakOut.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        let token_ = jwtDecode(action.payload.token);
        state.admin = token_;
        state.token = action.payload.token;

        setToken(action.payload.token);
        sessionStorage.setItem(
          "tokenE",
          token_ ? JSON.stringify(token_) : undefined
        );
        sessionStorage.setItem(
          "tokenSilE",
          action.payload.token ? action.payload.token : undefined
        );
        state.isLoading = false;
        setToast("success", action.payload.message);
      } else {
        setToast("error", action?.payload?.message);
      }
    });
    builder.addCase(breakOut.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default authSlice.reducer;
export const { setOldAdmin, logout } = authSlice.actions;
