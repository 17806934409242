import React, { useEffect, useState } from "react";
import Title from "../../extra/Title";
import Input, { Textarea } from "../../extra/Input";
import { ActionButton } from "../../extra/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAdminData,
  updateAdminPassword,
  updateImage,
  updateStaffImage,
} from "../../../redux/slice/authSlice";
import { baseURL } from "../../util/config";
import { editData, submitData } from "../../util/fuction";
import MaleProfile from "../../../assets/images/maleProfile.png";
import FemaleProfile from "../../../assets/images/femaleProfile.png";
import ParticleBackground from "../../Pages/ParticleBackground";

const Profile = () => {
  const { admin } = useSelector((state) => state.auth);
  const { loginUser } = useSelector((state) => state.dashBoard);
  const sessionUserId =
    loginUser || admin || sessionStorage.getItem(JSON.parse("tokenE"));

  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");

  useEffect(() => {
    if (sessionUserId) {
      editData(sessionUserId);
    }
  }, [sessionUserId]);

  const handleUploadImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };
  const dispatch = useDispatch();
  // Server Get
  const handleImage = () => {
    const formData = new FormData();
    formData.append("image", image);
    // dispatch(updateImage(formData));
    dispatch(updateStaffImage(formData));
  };

  const handleSubmit = async (e) => {
    const addBanner = submitData(e);
    if (addBanner) {
      await dispatch(updateAdminData(addBanner)).unwrap();
    }
  };

  return (
    <div>
      <div className="col-12">
        <Title name={"Profile"} icon={`ri-profile-line`} />
      </div>
      <div className="mainProfileBoard mainAdminProfile">
        <div className="mainProfileBox">
          <div className="row m40-sm-top m20-top">
            <div className="col-xxl-9 col-xl-10 col-lg-11 col-12 m-auto">
              <div className="adminBgDetails p30-sm-y p20 p130-xxl-x p90-xl-x p50-x">
                <ParticleBackground
                  particleColor={`#a02829`}
                  particleValue={100}
                />
                <div className="mainProfileDetails betBox flex-sm-row flex-column">
                  <div className="profilePosition text-second order-sm-0 order-1 m0-sm-bottom m35-bottom">
                    <h2 className="fs-md-40 fs-30 m5-bottom">
                      {sessionUserId.position}
                    </h2>
                    <p className="fw-600 fs-md-14 fs-12">
                      {sessionUserId.department} Department
                    </p>
                  </div>
                  <div className="profileImageVector hw-md-180 hw-100 m-sm-0 m20-bottom">
                    {sessionUserId.gender == "male" && (
                      <img src={MaleProfile} alt="" />
                    )}
                    {sessionUserId.gender == "female" && (
                      <img src={FemaleProfile} alt="" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xxl-9 col-xl-10 col-lg-11 col-12 m-auto">
              <div className=" text-center">
                <div className="profileNameImage p50-x d-flex align-items-center flex-sm-row flex-column justify-content-sm-start justify-content-center">
                  <div className="profileMainImage m30-sm-right">
                    <div className="adminProfileImage hw-lg-120 hw-md-100 hw-80 m-auto  position-relative">
                      <div className="entryImage hw-25 bg-second-light border-second-1 overflow-hidden ">
                        <i class="ri-pencil-fill fs-14 text-second"></i>
                        <input
                          id="file-input"
                          type="file"
                          accept="image/*"
                          className="adminImage"
                          onChange={(e) => handleUploadImage(e)}
                        />
                      </div>
                      <div className="adminImageHeader hw-lg-120 hw-md-100 hw-80 m-auto overflow-hidden border-second-3">
                        <img
                          src={
                            imagePath
                              ? imagePath
                              : baseURL + sessionUserId.image
                          }
                          alt="admin profile"
                          height={`100%`}
                          width={`100%`}
                        />
                      </div>
                    </div>
                    <div className="primeButton m10-top fs-lg-12 fs-sm-10 fs-10">
                      <ActionButton
                        className={`bg-second-light text-second border-second-2 fs-lg-12 fs-sm-10 fs-10`}
                        bIcon={`ri-file-pdf-line`}
                        text={`Update Image`}
                        onClick={handleImage}
                      />
                    </div>
                  </div>
                  <div className="adminText m20-top text-sm-start text-center">
                    <h3 className="m0 fs-lg-24 fs-sm-20 fs-xsm-16 fs-14 text-second">
                      {sessionUserId.staffName}
                    </h3>
                    <p className="fs-lg-16 fs-xsm-12 fs-12">
                      {sessionUserId.email}
                    </p>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit} id="adminDetailsForm">
                <div className="row m40-sm-top m20-top">
                  <div className="col-sm-6 col-12">
                    <div className="adminDetails bg-light p20">
                      <div className="adminProfileTitle">
                        Personal Information
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Name</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.staffName}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Date of birth</div>
                        <Input
                          type={`date`}
                          value={sessionUserId.dob}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox ">
                        <div className="adminDetailTitle">Gender</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.gender}
                          defaultValue={`--`}
                          disabled={true}
                          className={`text-capitalize`}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Addhar No</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.adharCard}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">PAN No</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.panCard}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Last Company</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.lastCompany}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Experience</div>
                        <Input
                          type={`text`}
                          id={`experience`}
                          value={`${sessionUserId.experience} Years`}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="adminDetails bg-light p20">
                      <div className="adminProfileTitle">Bank Details</div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Holder Name</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.holderName}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Account No</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.accountNo}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">IFSC</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.IFSC}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="adminDetails bg-light p20">
                      <div className="adminProfileTitle">Emergency Contect</div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Name</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.emergencyName}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Releation</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.emergencyRelation}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Phone No</div>
                        <Input
                          type={`text`}
                          value={`+91 ${sessionUserId.emergencyNumber}`}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="adminDetails bg-light p20">
                      <div className="adminProfileTitle">
                        General Information
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Job title</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.position}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Staff Code</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.staffCode}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Department</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.department}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Salary</div>
                        <Input
                          type={`text`}
                          value={`${sessionUserId.salary}₹`}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Start of work</div>
                        <Input
                          type={`date`}
                          value={sessionUserId.joiningDate}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Address</div>
                        <Textarea
                          type={`text`}
                          id={`address`}
                          name={`address`}
                          placeholder={`Address`}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="adminDetails bg-light p20">
                      <div className="adminProfileTitle">Working Details</div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Punch In Time</div>
                        <Input
                          type={`time`}
                          value={sessionUserId.startTime}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Punch Out Time</div>
                        <Input
                          type={`time`}
                          value={sessionUserId.endTime}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Break Time/Hour</div>
                        <Input
                          type={`Text`}
                          value={`${sessionUserId.breakTime} Hr`}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Total Hour</div>
                        <Input
                          type={`Text`}
                          value={`${sessionUserId.totalTime} Hr`}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="adminDetails bg-light p20">
                      <div className="adminProfileTitle">Contact Details</div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Email</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.email}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                      <div className="adminDetailsBox">
                        <div className="adminDetailTitle">Phone No</div>
                        <Input
                          type={`text`}
                          value={sessionUserId.phoneNumber}
                          defaultValue={`--`}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <ActionButton
        type={`submit`}
        className={`bg-second-light text-second border-second-2 m20-right`}
        bIcon={`ri-file-pdf-line`}
        text={`Update`}
        onClick={handleImage}
      /> */
}

export default Profile;

// // const ProfileBox = ({ title, count, color, icon }) =>
// }
