import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../extra/Input";
import { editData, submitData } from "../../../util/fuction";
import { closeDialog } from "../../../../redux/slice/dialogSlice";
import { batchAdd, batchUpdate } from "../../../../redux/slice/batchSlice";
import DialogBox from "../../../extra/DialogBox";

const BatchAdd = () => {
  const { dialogueData } = useSelector((state) => state.dialogue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
    }
  }, [dialogueData]);


  const handleSubmit = async (e) => {
    const addBatch = submitData(e);

    if (addBatch) {
      console.log("addBatch===============", addBatch);

      try {
        let response;
        if (dialogueData) {
          const payload = { addBatch, batchId: dialogueData._id };
          response = await dispatch(batchUpdate(payload)).unwrap();
        } else {
          response = await dispatch(batchAdd(addBatch)).unwrap();
        }
        console.log(response.status, "response.data.status");
        response.status && dispatch(closeDialog());
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  return (
    <DialogBox
      id={`studentForm`}
      title={`Batch Dialog`}
      handleSubmit={handleSubmit}
      columns={`col-xl-5 col-md-8 col-11`}
    >
      <div className="row align-items-start formBody">
        <div className="col-lg-6 col-12">
          <Input
            type={`text`}
            id={`batchName`}
            name={`batchName`}
            label={`Batch Name`}
            errorMessage={`Enter Batch Name`}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            type={`text`}
            id={`batchTime`}
            name={`batchTime`}
            label={`Batch Time`}
            errorMessage={`Enter Batch Time`}
          />
        </div>
      </div>
    </DialogBox>
  );
};

export default BatchAdd;
